import { useEffect, useState } from "react";
import { Dialog, DialogTitle } from "@headlessui/react";
import { getTenantId } from "@/lib/campaign/util";
import { getTransferEnabled, toggleTransfers } from "@/lib/call/util";
import { toggleQAMode, getQAMode } from "@/app/dashboard/setters/set-qa-mode";

interface SettingsModalProps {
  onClose: () => void;
}

export default function SettingsModal({ onClose }: SettingsModalProps) {
  const [enabled, setEnabled] = useState<boolean | null>(null);
  const [qaMode, setQaMode] = useState<boolean | null>(null);

  useEffect(() => {
    async function loadInitialState() {
      const tenantId = await getTenantId();
      if (tenantId) {
        const transferEnabled = await getTransferEnabled(tenantId);
        const qaModeEnabled = await getQAMode();
        setEnabled(transferEnabled);
        setQaMode(qaModeEnabled);
      }
    }
    void loadInitialState();
  }, []);

  const handleToggleQAMode = async () => {
    try {
      const newState = await toggleQAMode(!qaMode);
      setQaMode(newState);
    } catch (error) {
      console.error("Failed to toggle QA mode:", error);
    }
  };

  const handleToggleTransfers = async () => {
    const tenantId = await getTenantId();
    if (!tenantId) {
      return;
    }

    try {
      const newState = await toggleTransfers(tenantId);
      setEnabled(newState);
    } catch (error) {
      console.error("Failed to toggle transfers:", error);
    }
  };

  return (
    <Dialog open onClose={onClose} className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="fixed inset-0 bg-black opacity-30" />
        <div className="relative bg-white rounded mx-auto p-6 z-10 w-full max-w-md font-inter">
          <DialogTitle className="text-2xl font-bold mb-4">Settings</DialogTitle>
          {enabled === null ?
            (
              <div>Loading...</div>
            ) :
            (
              <div className="space-y-4 w-full">
                <div className="flex flex-col space-y-2 w-full">
                  <div className="flex items-center justify-between">
                    <span className="text-sm font-normal text-gray-700">Enable transfers</span>
                    <button
                      type="button"
                      className={`${
                        enabled ? "bg-blue-600" : "bg-gray-200"
                      } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none`}
                      onClick={() => {
                        void handleToggleTransfers();
                      }}
                    >
                      <span
                        className={`${
                          enabled ? "translate-x-5" : "translate-x-0"
                        } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                      />
                    </button>
                  </div>
                  <p className="text-sm font-normal text-gray-600">
                    {enabled ?
                      "When enabled, the AI can transfer calls to available agents as needed." :
                      "When disabled, the AI will place a note for follow-up instead of transferring calls."}
                  </p>
                </div>
                <div className="flex flex-col space-y-2 w-full">
                  <div className="flex items-center justify-between">
                    <span className="text-sm font-normal text-gray-700">QA Mode</span>
                    <button
                      type="button"
                      className={`${
                        qaMode ? "bg-blue-600" : "bg-gray-200"
                      } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none`}
                      onClick={() => {
                        void handleToggleQAMode();
                      }}
                    >
                      <span
                        className={`${
                          qaMode ? "translate-x-5" : "translate-x-0"
                        } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                      />
                    </button>
                  </div>
                  <p className="text-sm font-normal text-gray-600">
                    When enabled, QA mode allows viewing calls without external permissions.
                  </p>
                </div>
              </div>
            )}
          <button
            onClick={onClose}
            className="mt-6 w-full bg-dark_green_bg text-white py-2 px-4 rounded-md hover:brightness-90 font-normal"
          >
            Close
          </button>
        </div>
      </div>
    </Dialog>
  );
}
